exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-our-projects-js": () => import("./../../../src/pages/en/our-projects.js" /* webpackChunkName: "component---src-pages-en-our-projects-js" */),
  "component---src-pages-en-our-projects-strapi-project-id-js": () => import("./../../../src/pages/en/our-projects/{strapiProject.id}.js" /* webpackChunkName: "component---src-pages-en-our-projects-strapi-project-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasze-projekty-js": () => import("./../../../src/pages/nasze-projekty.js" /* webpackChunkName: "component---src-pages-nasze-projekty-js" */),
  "component---src-pages-nasze-projekty-strapi-project-id-js": () => import("./../../../src/pages/nasze-projekty/{strapiProject.id}.js" /* webpackChunkName: "component---src-pages-nasze-projekty-strapi-project-id-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */)
}

